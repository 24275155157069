import { defineStore } from 'pinia'
import { store } from '../index'
import { orderRemind, businessOrderRemind } from '@/api/mall/platformOrder'
import { useUserStore } from '@/store/modules/user'

export const useOrderRemindStore = defineStore('orderRemind', {
  state: () => {
    return {
      hasNewOrder: false,
      orderType: 10,
      timer: null as null | NodeJS.Timeout
    }
  },
  getters: {
    getOrderType(): number {
      return this.orderType
    },
    gethasNewOrder(): boolean {
      return this.hasNewOrder
    }
  },
  actions: {
    SET_NEW_ORDER(type) {
      this.hasNewOrder = true
      this.orderType = type
      this.playAudio()
    },
    CLEAR_NEW_ORDER() {
      this.hasNewOrder = false
    },
    playAudio() {
      const audio: any = document.getElementById('newOrderAudio')
      if (audio) {
        audio.play().catch((error) => {
          console.error('播放音频出错:', error)
        })
      } else {
        console.error('播放音频出错:')
      }
    },
    clearTimer() {
      if (this.timer !== null) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    // 十秒执行一次
    requestOrderRemind() {
      this.timer = setInterval(async () => {
        // 假设这里有一个名为fetchOrders的接口调用函数
        // 仓库提醒
        const user = useUserStore()
        const platformType = user?.userInfo?.admin?.business_id ? 'business' : 'platform'
        if (platformType === 'business') {
          const { code, data } = await businessOrderRemind()
          if (code == 200) {
            if (data.flag) {
              this.SET_NEW_ORDER(data.type)
            }
          }
        } else {
          const { code, data } = await orderRemind()
          if (code == 200) {
            if (data.flag) {
              this.SET_NEW_ORDER(data.type)
            }
          }
        }
      }, 10000)
    }
  }
})

export const useOrderRemindStoreWithOut = () => {
  return useOrderRemindStore(store)
}

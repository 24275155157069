import type { DirectiveBinding } from 'vue'
import { usePerStore } from '@/store/modules/per'

// 权限指令
const PermissionDirective = {
  // 元素挂载时检查权限
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding)
  },
  // 元素更新时检查权限
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding)
  }
}

// 检查权限并处理元素显示
function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { hasPermission } = usePerStore()
  const { value } = binding
  if (!hasPermission(value)) {
    el?.parentElement?.removeChild(el)
  }
}
export default PermissionDirective

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from './user'
export const usePerStore = defineStore('per', () => {
  // 权限列表，初始包含 'view', 'edit', 'delete', 'admin'
  const userStore = useUserStore()
  const permissions = ref<string[]>(userStore.getUserInfo?.role?.unit || [])
  const menuPermissions = ref<string[]>(userStore.getUserInfo?.role?.role || [])
  // 检查是否拥有指定权限
  const hasPermission = computed(() => (permission: string | string[]) => {
    if (!permission) {
      return false
    }
    if (typeof permission === 'string' && permission.includes(',')) {
      permission = permission.split(',')
    }
    if (Array.isArray(permission)) {
      return permission.some((p) => permissions.value.includes(p))
    }
    return permissions.value.includes(permission)
  })

  // 设置新的权限列表
  function setPermissions(newPermissions: string[]) {
    permissions.value = newPermissions
  }

  // 移除指定权限
  function removePermission(permission: string | string[]) {
    if (Array.isArray(permission)) {
      permissions.value = permissions.value.filter((p) => !permission.includes(p))
    } else {
      permissions.value = permissions.value.filter((p) => p !== permission)
    }
  }

  return { permissions, hasPermission, setPermissions, removePermission, menuPermissions }
})

import request from '@/axios'

export const businessDeliveryApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/business/delivery', data })
}
export const infoApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/order/platform/info', params })
}

export const listApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/order/platform/index', params })
}
export const businessListApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/order/business/index', params: data })
}
export const businessInfoApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/order/business/info', params: data })
}
export const changeAddressApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/changeAddress', data })
}

export const operationListApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/order/platform/operationList', params })
}
export const addOperationApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/addOperation', data })
}

export const exaApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/exa', data: params })
}

export const offlinePayApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/offlinePay', data })
}

export const messageApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/remark', data })
}

export const deliveryApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/delivery', data })
}

export const OrderGoodsPriceLogApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/order/platform/getChangeOrderGoodsPriceLog', params })
}

export const changePriceApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/changePrice', data })
}

export const agentOrderByPlatformApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/agentOrderByPlatform', data: params })
}
export const previewOrderApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/order/platform/previewOrder', data: params })
}
// 订单提醒功能
export const orderRemind = (): Promise<IResponse> => {
  return request.get({ url: '/order/platform/remind' })
}
export const businessOrderRemind = (): Promise<IResponse> => {
  return request.get({ url: '/order/business/remind' })
}
